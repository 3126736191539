import {
  isRouteErrorResponse,
  useMatches,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import {
  addBreadcrumb,
  captureException,
  captureRemixErrorBoundaryError,
  withScope,
} from '@sentry/remix'

import useCurrentLocation from './useCurrentLocation'
import useServerReport from './useServerReport'

/**
 * Collect error and report to sentry
 * @returns The original remix useRouteError value
 */
export const useRouteErrorWithReporter = ({
  retriesFromUrl,
}: {
  retriesFromUrl?: number
} = {}) => {
  const error = useRouteError()
  const routeData = useMatches()
  const [url, cleanUrl, search] = useCurrentLocation()
  const rootData = useRouteLoaderData<{
    userIdentityId?: string
    env?: { appVersion?: string }
  }>('root')

  const retries = Number(new URLSearchParams(search).get('retries') ?? 0)

  const version = rootData?.env?.appVersion
  const transactionName = `${rootData?.env?.appVersion} ${cleanUrl}`

  useServerReport({
    error,
    data: {
      userIdentityId: rootData?.userIdentityId ?? null,
      appVersion: version ?? null,
      url,
    },
  })

  const breadcrumbRouteData = routeData?.reduce((acc, v) => {
    acc[v.id] = v

    return acc
  }, {} as Record<string, any>)

  const errorMessage = `useRouteErrorWithReporter > ${cleanUrl}`

  addBreadcrumb({
    level: 'error',
    message: errorMessage,
    data: {
      error,
    },
  })

  addBreadcrumb({
    level: 'info',
    message: 'useRouteErrorWithReporter > routes data',
    data: {
      routesData: breadcrumbRouteData,
    },
  })

  // To avoid spam, let just inform sentry when the error persists.
  const shouldReportHandledClient = retriesFromUrl
    ? retries === retriesFromUrl
    : true

  if (isRouteErrorResponse(error) && error.status !== 404) {
    // captureRemixErrorBoundaryError expects a object to do a spread
    // https://github.com/getsentry/sentry-javascript/blob/c9aaf8b7db326e2e39254301137f6b3310abc294/packages/remix/src/client/errors.tsx#L21
    if (typeof error.data === 'string') {
      error.data = { data: error.data }
    }

    withScope(scope => {
      scope.setContext('Error Response in Remix Boundary', {
        status: error.status,
        statusText: error.statusText,
        data: error.data,
        url,
      })
      scope.setFingerprint([
        'Type: Server Route',
        `Route: ${cleanUrl}`,
        `Message: ${error.statusText}`,
      ])
      scope.setTransactionName(transactionName)
      captureRemixErrorBoundaryError(error)
    })
  } else if (isRouteErrorResponse(error) && error.status === 404) {
    // We are ignoring 404 errors on sentry, as we have no actionables.
    console.error('Not found!')
  } else if (error instanceof Error && shouldReportHandledClient) {
    const sendError = new Error(errorMessage, { cause: error })
    sendError.name = error.message

    withScope(scope => {
      if (error.stack === undefined)
        scope.setContext('useRouteErrorWithReporter > Client Error', {
          note: 'It could be a sanatized server error (https://remix.run/docs/en/main/guides/errors#error-sanitization). In this case, check for server reports.',
        })

      scope.setFingerprint([
        'Type: Client',
        `Route: ${cleanUrl}`,
        `Message: ${error.message}`,
      ])
      scope.setTransactionName(transactionName)
      captureException(sendError)
    })
  } else if (!(error instanceof Error)) {
    withScope(scope => {
      scope.setContext('useRouteErrorWithReporter > Unknown Error Type', {
        note: 'Unknwon object error.',
        errorType: typeof error,
        url,
      })
      scope.setFingerprint(['Type: Client Unknown', `Route: ${cleanUrl}`])
      scope.setTransactionName(transactionName)
      captureException(new Error(errorMessage, { cause: error }))
    })
  }

  return error
}
